import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import ImageHelpers from 'utils/helpers/ImageHelpers';

interface INoStoreCenterSectionProps {}

const NoStoreCenterSection: FC<INoStoreCenterSectionProps> = ({}) => {
  return (
    <>
      <VStack py={12} display={{ base: 'flex', md: 'none' }}>
        <Image
          height={{ base: '35px', md: 'unset' }}
          src={ImageHelpers.resolveStaticImage('assets/common/cottage_logo_and_name.svg')}
          alt="Logo"
        />
      </VStack>
      <VStack pos="absolute" top="50%" bottom="50%" left="5px" right="5px" justify="center">
        <HStack justify="center">
          <Image
            w={[7 / 16, 7 / 16, 10 / 16, 12 / 16]}
            src={ImageHelpers.resolveStaticImage('assets/common/cottage_logo_background_faded.svg')}
            alt="Background image with logo"
          />
        </HStack>
        <Text pos="absolute" fontSize={{ md: 'lg' }} color="cottage_gray.500">
          Sorry, there is no shop with this name.
        </Text>
      </VStack>
    </>
  );
};
export default NoStoreCenterSection;
