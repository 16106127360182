import { Container } from '@chakra-ui/react';
import {
  NoStoreBottomNavMobile,
  NoStoreCenterSection,
  NoStoreUpperNavDesktop,
} from 'containers/NoStore';
import Head from 'next/head';

const NoStore = () => {
  const SIGN_UP_LINK_EXTERNAL = `https://cottage.menu/signup`;

  return (
    <>
      <Head>
        <title>{'Store Not Found'}</title>
      </Head>
      <Container maxW="1440px">
        <NoStoreUpperNavDesktop SIGN_UP_LINK_EXTERNAL={SIGN_UP_LINK_EXTERNAL} />
        <NoStoreCenterSection />
      </Container>
      <NoStoreBottomNavMobile SIGN_UP_LINK_EXTERNAL={SIGN_UP_LINK_EXTERNAL} />
    </>
  );
};

export default NoStore;
