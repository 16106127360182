import { Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import ImageHelpers from 'utils/helpers/ImageHelpers';

interface INoStoreUpperNavDesktopProps {
  SIGN_UP_LINK_EXTERNAL: string;
}

const NoStoreUpperNavDesktop: FC<INoStoreUpperNavDesktopProps> = ({ SIGN_UP_LINK_EXTERNAL }) => {
  return (
    <Flex justify="space-between" py={6} display={{ base: 'none', md: 'flex' }}>
      <Image
        src={ImageHelpers.resolveStaticImage('assets/common/cottage_logo_and_name.svg')}
        alt="Logo"
      />
      <Flex align="center" gap={6}>
        <Text fontSize="md" color="cottage_gray.400">
          Want to start a shop?
        </Text>
        <Link href={SIGN_UP_LINK_EXTERNAL}>
          <Button fontSize="md" colorScheme="cottage_green">
            Sign Up for Cottage
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default NoStoreUpperNavDesktop;
