import { Box, Button, Link, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface INoStoreBottomNavMobileProps {
  SIGN_UP_LINK_EXTERNAL: string;
}

const NoStoreBottomNavMobile: FC<INoStoreBottomNavMobileProps> = ({ SIGN_UP_LINK_EXTERNAL }) => {
  return (
    <Box
      bg="cottage_green.500"
      w="full"
      py={6}
      display={{ base: 'block', md: 'none' }}
      pos="absolute"
      bottom="0">
      <VStack>
        <Text fontSize="md" color="white">
          Want to start a shop?
        </Text>
        <Link href={SIGN_UP_LINK_EXTERNAL}>
          <Button colorScheme="cottage_green" fontSize="md" color="white" bg="gray.900">
            Sign Up for Cottage
          </Button>
        </Link>
      </VStack>
    </Box>
  );
};
export default NoStoreBottomNavMobile;
